<template>
  <div class="Group_Introduction">
    <van-popup
      v-model="this.$store.state.show"
      position="right"
      :style="{ height: '100%', width: '223px' }"
      class="genduomenu"
    >
      <el-input
        suffix-icon="el-icon-search"
        class="PeSearch"
        v-model="searchText"
      >
      </el-input>
      <ul class="peMenu">
        <li @click="toHome">
          <span>首页</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toBusiness">
          <span>经营范围</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toNews">
          <span>新闻资讯</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toAdvantage">
          <span>核心优势</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toGroup_Introduction">
          <span>集团介绍</span><i class="el-icon-arrow-right"></i>
        </li>
        <li @click="toContact_us">
          <span>联系我们</span><i class="el-icon-arrow-right"></i>
        </li>
      </ul>
    </van-popup>
    <div id="header">
      <div class="top">
        <img src="@/assets/Home/login.png" alt="" class="logo" />
        <ul class="left">
          <li class="first" @click="toHome">
            <img src="@/assets/Home/homeIcon.png" class="homeIcon" alt="" />
            {{ $t('home.HomePage') }}
          </li>
          <li @click="toBusiness">{{ $t('home.businessScope') }}</li>
          <li @click="toNews">{{ $t('home.News') }}</li>
          <li @click="toAdvantage">{{ $t('home.Advantage') }}</li>
          <li>{{ $t('home.Introduce') }}</li>
          <li @click="toContact_us">{{ $t('home.ContactUs') }}</li>
        </ul>
        <div class="right">
          <el-input suffix-icon="el-icon-search" v-model="searchText">
          </el-input>
          <Lang></Lang>
        </div>
        <img
          class="rightPe"
          @click.stop="showMenu"
          src="@/assets/PE/Home/436.png"
          alt=""
        />
      </div>
    </div>
    <div class="layer1" @click.stop="popHide">
      <div class="titles">
        <h2>
          {{ $t('Group_Introduction.GroupIntroduction') }}<span>/</span>
          <span>{{ $t('Group_Introduction.GroupIntroductionE') }}</span>
        </h2>
      </div>
      <el-divider></el-divider>
      <div class="content">
        <div class="title">{{ $t('Group_Introduction.AboutUs') }}</div>
        <div class="article">
          {{ $t('Group_Introduction.Article') }}
        </div>
        <img src="../../assets/Group_Introduction/gywm (1).png" alt="" />
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import footers from '../../components/footer.vue'
import Lang from '../../components/Lang.vue'
export default {
  components: {
    footers,
    Lang
  },
  data () {
    return {
      searchText: ''
    }
  },
  created () {
    this.$store.commit('setShow', false)
  },
  methods: {
    toHome () {
      this.$router.push('/Home')
    },
    toBusiness () {
      this.$router.push('/Business')
    },
    toNews () {
      this.$router.push('/News')
    },
    toAdvantage () {
      this.$router.push('/Advantage')
    },
    toContact_us () {
      this.$router.push('/Contact_us')
    },
    toGroup_Introduction () {
      this.$router.push('/Group_Introduction')
    },
    showMenu () {
      this.$store.commit('setShow', true)
      console.log(this.$store.state.show)
    },
    popHide () {
      this.$store.commit('setShow', false)
    }
  }
}
</script>

<style lang="less" scoped>
.Group_Introduction {
  #header {
    width: 100%;
    height: 800px;
    background: url('../../assets/Group_Introduction/457.png') no-repeat;
    background-size: cover;
    position: relative;
    .top {
      width: 1400px;
      height: 80px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      .logo {
        width: 95px;
        height: 27px;
        margin-right: 30px;
      }
      .left {
        width: 1000px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          color: #fff;
          list-style: none;
          display: block;
          cursor: pointer;
          font-size: 16px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          text-align: left;
          .homeIcon {
            margin-right: 10px;
          }
        }
        .first {
          border-left: 1px solid #fff;
          padding-left: 21px;
          display: flex;
          align-items: center;
        }
      }
      .right {
        margin-left: 145px;
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        /deep/ .el-input {
          width: 206px;
          height: 36px;
          .el-input__inner {
            background-color: rgba(255, 255, 255, 0.3);
            -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
            border-radius: 25px;
            border: 0;
            color: #fff;
          }
        }
      }
      .rightPe {
        display: none;
      }
    }
  }
  .layer1 {
    width: 1160px;
    margin: 0 auto;
    .titles {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 80px;
      line-height: 80px;
      padding-bottom: 60px;
      margin-top: 90px;
      h2 {
        display: block;
        width: 760px;
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        display: flex;
      }
      span {
        font-size: 16px;
        font-family: Didot, Didot-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        &:nth-child(1) {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
    .content {
      .title {
        font-size: 40px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin-top: 50px;
      }
      .article {
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
        line-height: 38px;
        padding-top: 90px;
        padding-bottom: 90px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .Group_Introduction {
    #header {
      width: 100%;
      height: 150px;
      background: url('../../assets/Group_Introduction/457.png') no-repeat;
      background-size: cover;
      position: relative;
      .top {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        .logo {
          width: 95px;
          height: 27px;
          margin-right: 30px;
          margin-left: 15px;
        }
        .left {
          display: none !important;
          width: 1000px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            color: #fff;
            list-style: none;
            display: block;
            cursor: pointer;
            font-size: 16px;
            font-family: Microsoft YaHei, Microsoft YaHei-Regular;
            font-weight: 400;
            text-align: left;
            .homeIcon {
              margin-right: 10px;
            }
          }
          .first {
            border-left: 1px solid #fff;
            padding-left: 21px;
            display: flex;
            align-items: center;
          }
        }
        .right {
          display: none !important;
          margin-left: 145px;
          width: 280px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          /deep/ .el-input {
            width: 206px;
            height: 36px;
            .el-input__inner {
              background-color: rgba(255, 255, 255, 0.3);
              -webkit-backdrop-filter: blur(2px);
              backdrop-filter: blur(2px);
              border-radius: 25px;
              border: 0;
              color: #fff;
            }
          }
        }
        .rightPe {
          display: block;
          width: 21px;
          height: 16.5px;
          position: absolute;
          right: 25px;
          top: 20.5px;
        }
      }
    }
    .layer1 {
      width: 100%;
      margin: 0;
      padding-left: 20px;
      padding-right: 20px;
      .peHied {
        display: none;
      }
      .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 0px;
        align-items: center;
        margin-top: 0px;
        h2 {
          display: block;
          width: 360px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          text-align: left;
          color: #333333;
          display: flex;
          align-items: center;
        }
        span {
          font-size: 12px;
          font-family: Didot, Didot-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          &:nth-child(1) {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .el-divider {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .title {
        margin-top: 20px !important;
        font-size: 15px !important;
        font-family: Microsoft YaHei, Microsoft YaHei-Regular;
        font-weight: 400;
        text-align: left;
        color: #333333;
      }
      .article {
        font-size: 12px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      img {
        display: block;
        width: 100%;
        height: 219.5px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
<style lang="less">
.genduomenu {
  width: 223px;
  background-color: #3333336a !important;
  backdrop-filter: blur(8px);
  // border-radius: 40px;
  position: absolute;
  right: 0;
  top: 0;
  .PeSearch {
    width: 188px;
    height: 30px;
    border-radius: 40px;
    margin-left: 20px;
    margin-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    .el-input__inner {
      background-color: rgba(255, 255, 255, 0.226) !important;
      color: #fff;
      border-radius: 40px;
    }
  }
  .peMenu {
    width: 100%;
    li {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      padding: 0 15px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
    }
  }
}
</style>
